.text-area-comment {
  height: 60px !important;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  font-size: 14px !important;
  padding: 10px;
  font-family: "Rubik";
}

.grid-form-template {
  width: auto;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.pad-10 {
  padding: 1px !important;
}
