.info-damage-block {
  width: auto;
  border-radius: 10px;
  display: grid;
  height: fit-content;
  grid-template-columns: auto 1fr auto;
  grid-gap: 5px;
  margin-bottom: 20px;
}

.btn-add-to-damage {
  padding: 7px 15px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  border: none;
}

.btn-add-to-damage:hover {
  background-color: #002ac2 !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(44, 139, 255, 0.81);
}

.btn-add-to-damage:active {
  background-color: #436cff !important;
  transform: scale(0.9) !important;
  box-shadow: none;
}

block-grid-damages-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.input-d {
  padding: 7px 15px;
  border: none;
  border-radius: 10px;
  background-color: #f0f0f0;
  width: 255px;
  height: 25px;
  /* font-family: 'Rubik'; */
  /* font-weight: bold; */
  font-size: 16px;
  font-weight: 600;
}

.text-form-main {
  font-size: 16px;
  font-weight: 700;
}

.block-info-damage-card {
  background-color: white;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 20px;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.text-form {
  font-size: 15px;
  color: gray;
}

.grid-info-block {
  display: flex;
  align-items: center;
}

.css-1ui5xat-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-size: 13px !important;
  border-radius: 10px !important;
  color: #000000 !important;
  background-color: #8bc34a69 !important;
  padding: 0px !important;
  width: 110px !important;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px !important;
  text-align: left !important;
  /* border: gray; */
  padding-left: 10px !important;
}

.css-okkh19-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-size: 13px !important;
  border-radius: 10px !important;
  color: #000000 !important;
  background-color: #c34a4a69 !important;
  padding: 0px !important;
  width: 110px !important;
}

.ta-end {
  text-align: end;
}

.p-edit-btn {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.btn-edit-to-damage {
  padding: 7px 10px 7px 10px !important;
  color: white !important;
  background-color: #436cff !important;
  text-transform: capitalize !important;
  width: 120px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
  height: fit-content !important;
}

.btn-edit-to-damage:hover {
  background-color: #002ac2 !important;
  transform: scale(1.07) !important;
  box-shadow: 1px 4px 37px 4px rgba(44, 139, 255, 0.81);
}

.btn-edit-to-damage:active {
  background-color: #436cff !important;
  transform: scale(0.9) !important;
}

.textarea-damage {
  height: 100px !important;
  border: none;
  border-radius: 10px !important;
  font-size: 14px !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  width: -webkit-fill-available !important;
  background-color: #f0f0f0;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Roboto" !important;
  padding: 13px !important;
}
